<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "Settings",
  data() {
    return {};
  },
};
</script>
